<template>
  <div class="container resultView">
    <div>
      <span class="back" @click="back">
        <i class="el-icon-arrow-left"></i> 返回
      </span>
    </div>
    <div class="commonWhiteBg">
      <div class="commonTitle">提交预览</div>
      <div class="MainStepsForm">
        <div class="formWrap">
          <el-form ref="form" label-width="120px">
            <el-form-item label="作品名称" prop="name">
              {{ orderInfo.subject }}
            </el-form-item>

            <el-form-item label="作者" prop="author">
              {{ orderInfo.author }}
              <!-- <span v-for="(author, index) in orderInfo.author" :key="index">{{
                author.obligeeType == "1" ? author.userName : author.companyName
              }}</span> -->
            </el-form-item>
            <el-form-item label="著作权人" prop="right">
              <span v-for="(author, index) in orderInfo.right" :key="index">{{
                author.obligeeType == "1" ? author.userName : author.companyName
              }}</span>
            </el-form-item>
             <el-form-item label="作品类型" prop="workCategory" v-if="orderInfo.workCategory">
              {{ constants.worksType[orderInfo.workCategory * 1-1].label }}
            </el-form-item>
            <el-form-item label="创作性质" prop="creationNature" v-if="orderInfo.creationNature">
              {{ constants.creationNature[orderInfo.creationNature * 1-1].label }}
            </el-form-item>

            <el-form-item label="已上传作品" prop="name">
              <ul class="commonFileList">
                <li v-for="(item, index) in orderInfo.files" :key="index">
                  <a-icon
                    v-if="
                      item.fileType == 'png' ||
                      item.fileType == 'jpg' ||
                      item.fileType == 'jpeg'
                    "
                    type="file-image"
                  />
                  <a-icon v-if="item.fileType == 'pdf'" type="file-pdf" />
                  <a-icon v-if="item.fileType == 'ppt'" type="file-ppt" />
                  <a-icon
                    v-if="item.fileType == 'docx' || item.filetType == 'doc'"
                    type="file-word"
                  />
                  <a-icon
                    v-if="item.fileType == 'xlsx' || item.fileType == 'xls'"
                    type="file-excel"
                  />
                  <a-icon v-if="item.fileType == 'zip'" type="file-zip" />
                  <span> {{ item.sourceName }}</span>
                </li>
              </ul>
            </el-form-item>
            <el-form-item
              label="支付方式"
              prop="payStyle"
              class="choosePayStyle"
            >
              <el-radio-group v-model="orderInfo.paymode">
                <el-radio label="vip" v-if="orderInfo.paymode == 'vip'">
                  <span class="onlyText"
                    >中国知链会员权益支付（当前存证次数可用{{
                      vipInfo ? vipInfo.existCount : 0
                    }}次）</span
                  >
                </el-radio>
                <el-radio label="alipay" v-if="orderInfo.paymode == 'alipay'">
                  <i class="iconfont aliPayColor"> &#xe675; </i>
                  <span>支付宝支付</span></el-radio
                >
                <el-radio label="wechat" v-if="orderInfo.paymode == 'wechat'">
                  <i class="iconfont weChatColor"> &#xe678; </i>
                  <span>微信支付</span></el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="支付金额" v-if="orderInfo.paymode != 'vip'">
              <div>
                <em class="bigPrice">{{ orderInfo.orderAmount }} </em> 元
              </div>
            </el-form-item>
            <el-form-item class="submitRow">
              <el-button type="primary" @click="onGoPay">确认并支付</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <pay-dialog ref="payDialog" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
// import PayDialog from "@/views/multipleService/components/PayDialog";
import PayDialog from "../../../../components/PayDialog";
import { mapGetters } from "vuex";
import { applyExistOrder } from "@/api/EvidenceService";
import constants from "@/utils/constants";
export default {
  name: "",
  components: { PayDialog },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  data() {
    return {
      constants,
      isPayDialogVisible: false,
      Info: null,
    };
  },
  props: {
    orderInfo: Object,
  },
  created() {
    this.init();
    console.log("------------",this.orderInfo);
  },
  methods: {
    init() {
      console.log("init result ..");
    },
    back() {
      this.$emit("set-tab-idx", "tab1");
    },
    onGoPay() {
      let that=this;
      if(that.Info){
          that.$refs.payDialog.showDialog(this.Info);
          that.isPayDialogVisible = true;
          return false;
      }

      let params = {
        subject: that.orderInfo.subject,
        syncBlockChain: "btc",
        items: that.orderInfo.files,
        paymode: that.orderInfo.paymode,
        authors: that.orderInfo.author.split('，'),
        // authors: that.orderInfo.author.map((t) => {
        //   return t.obligeeCode;
        // }),
        obligee: that.orderInfo.right.map((t) => {
          return t.obligeeCode;
        }),
        workCategory: that.orderInfo.workCategory,
        creationNature: that.orderInfo.creationNature,
      };
      applyExistOrder(params).then(({ data, res }) => {
        that.Info = data;
        console.log("支付：",data);
        if (data.paymentStatus == "21") {
          // 21 是会员支付； 微信，支付宝都是10
          that.bus.$emit("refreshVip");
          that.onSetPayDialogClose(true);
        } else {
          that.$refs.payDialog.showDialog(data);
          that.isPayDialogVisible = true;
        }
      });
    },
    onSetPayDialogClose(result) {
      console.log(result);
      if (result) {
        //跳转至详情页
        this.$router.push({
          path: "/ExistingEvidence/Detail",
          query: { orderNo: this.Info.orderNo },
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../../../assets/css/inputForm.scss";
</style>
