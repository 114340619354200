<template>
  <div>
    <el-dialog
      width="460px"
      center
      :title="title"
      :visible.sync="visible"
      @closed="onClose"
    >
      <div class="checkPersonList">

        <el-checkbox-group v-model="selectType">
          <el-checkbox
            v-for="(item,index) in options"
            :key="index"
            :label="index"
          > {{ item.obligeeType=='1'?item.userName:item.companyName}}</el-checkbox>
        </el-checkbox-group>
        <div class="btnWrap">
          <el-button
            v-if="JSON.stringify(linkObj)!='{}'"
            type="text"
            @click="goto(linkObj.path)"
          >{{linkObj.label}}</el-button>
          <el-button type="primary" @click="onSubmit">确认添加</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'choosePersonDialog',
  props: {
    // 类型
    listOpt: {
      type: Array,
      required: true
    },
    value: {
      type: [Number, String, Array],
    },
    title: {
      type: String,
      default: ''
    },
    linkObj: {
      type: Object,
      default: val => ({})
    }
  },
  data () {
    return {
      // 弹框是否显示
      visible: false,
      selectType: [],
      options: []
    }
  },
  watch: {
    // value: {
    //   handler (newVal, oldVal) {
    //     this.selectType = newVal;
    //   },
    //   immediate: true //第一次进来触发
    // },
  },
  created () {
    this.visible = true
    this.init()
  },
  mounted () {
    this.$nextTick(() => {
      this.options = _.cloneDeep(this.listOpt)
    })
  },
  methods: {
    init () {
      console.log("init choose..")
    },
    goto (path) {
      this.$router.push({
        path: path,
        query: {}
      });
    },
    onSubmit(){
      let arr=[];
        for (let i = 0; i < this.selectType.length; i++) {
          const element = this.selectType[i];
          arr.push(this.listOpt[element*1]);
        }
          this.$emit('input',arr);
          this.onClose()
    },
    // 关闭弹框时触发的事件
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
.checkPersonList {
  .btnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
/deep/ .el-dialog--center .el-dialog__body{
    padding-top:0;
}
</style>
