<template>
  <div class="MainStepsForm">
    <div  v-show="tabIdx=='tab1'" class="container">
      <a-alert
        message="平台为你自行上传的作品进行版权存证，不对您上传内容的真实性进行验证"
        type="warning"
        show-icon
      />

      <div class="commonWhiteBg">
        <!-- <div class="ant-popover-arrow"></div> -->
        <div class="commonTitle"> 版权存证 </div>
        <div class="formWrap">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="150px"
          >
            <el-form-item
              label="作品名称"
              prop="subject"
            >
              <el-input v-model="form.subject"></el-input>
            </el-form-item>
           
            <!-- <el-form-item label="作者" class="personList" prop="author">
              <span v-for="(author, index) in form.author" :key="index">{{
                author.obligeeType == "1" ? author.userName : author.companyName
              }}</span>
              <el-button type="text" @click="isAuthorDialogVisible = true"
                >选择作者</el-button
              >
              <choose-person-dialog
                title="选择作者"
                v-if="isAuthorDialogVisible"
                v-model="form.author"
                :listOpt="obligees"
                :linkObj="{ label: '添加作者', path: '/Mine/RealName' }"
                @close="onSetAuthorDialogClose"
                @input="onChooseAuthor"
              ></choose-person-dialog>
            </el-form-item> -->
            <el-form-item
              label="作者"
              prop="author"
            >
              <el-input v-model="form.author"></el-input>
            </el-form-item>

            <el-form-item class="personList"  label="著作权人" prop="right">
              <span v-for="(author, index) in form.right" :key="index"
                >{{
                  author.obligeeType == "1"
                    ? author.userName
                    : author.companyName
                }}&nbsp;</span
              >
              <el-button type="text" @click="isRightDialogVisible = true"
                >选择权利人</el-button
              >
              <choose-person-dialog
                title="选择权利人"
                v-if="isRightDialogVisible"
                v-model="form.right"
                :listOpt="obligees"
                :linkObj="{ label: '添加权利人', path: '/Mine/Obligee' }"
                @close="onSetRightDialogClose"
                @input="onChooseRight"
              ></choose-person-dialog>
            </el-form-item>

            <el-form-item label="作品类型" prop="workCategory">     <!--  这里存证里面的参数名worksType 改为 workCategory -->
              <el-select
                  v-model="workCategoryText"
                  placeholder="请选择创作类型"
                  @change="selectworkCategory"
              >
                <el-option
                v-for="(item, index) in constants.worksType"
                :key="index"
                :label="item.label"
                :value="index"
                >
                </el-option>
              </el-select>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '1'">
                    <div slot="description" >
                      文字：小说、诗词、散文、论文等以文字形式表现的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '2'">
                    <div slot="description" >
                      口述：即兴的演说、授课、法庭辩论等以口头语言形式表现的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '3'">
                    <div slot="description" >
                      音乐：歌曲、交响乐等能够演唱或者演奏的带词或者不带词的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '4'">
                    <div slot="description" >
                      戏剧：话剧、歌剧、地方戏等供舞台演出的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '5'">
                    <div slot="description" >
                      曲艺：相声、快书、大鼓、评书等以说唱为主要形式表演的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '6'">
                    <div slot="description" >
                      舞蹈：通过连续的动作、姿势、表情等表现思想情感的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '7'">
                    <div slot="description" >
                      杂技：杂技、魔术、马戏等通过形体动作和技巧表现的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '8'">
                    <div slot="description" >
                      美术：绘画、书法、雕塑等以线条、色彩或者其他方式构成的有审美意义的平面或者立体的造型艺术作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '9'">
                    <div slot="description" >
                      建筑：以建筑物或者构筑物形式表现的有审美意义的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '10'">
                    <div slot="description" >
                      摄影：借助器械在感光材料或者其他介质上记录客观物体形象的艺术作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '11'">
                    <div slot="description" >
                      电影：是指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '12'">
                    <div slot="description" >
                      类似摄制电影方法创作的作品：是指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品（电视剧，动画片，视频，录音、录像制品等都归于这一类）。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '13'">
                    <div slot="description" >
                      工程设计图、产品设计图：为施工、生产绘制的工程设计图、产品设计图，以及反映地理现象、说明事物原理或者结构的地图、示意图等作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '14'">
                    <div slot="description" >
                      地图、示意图：为施工、生产绘制的工程设计图、产品设计图，以及反映地理现象、说明事物原理或者结构的地图、示意图等作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '15'">
                    <div slot="description" >
                      模型：为展示、试验或者观测等用途，根据物体的形状和结构，按照一定比例制成的立体作品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '16'">
                    <div slot="description" >
                      录音制品：任何对表演的声音和其他声音的录制品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '17'">
                    <div slot="description" >
                      录像制品：电影作品和以类似摄制电影的方法创作的作品以外的任何有伴音或无伴音的连续相关形象、图像的制品。
                    </div>
              </a-alert>
              <a-alert class="tips" type="warning" v-if="form.workCategory == '18'">
                    <div slot="description" >
                      其他：根据法律法规规定的其他作品。选择“其他”类时，请说明具体情况。
                    </div>
              </a-alert>
            </el-form-item>
            <el-form-item label="创作性质" prop="creationNature">
                <el-select
                    v-model="creationNatureText"
                    placeholder="请选择作品创作性质"
                    @change="selectCreationNature"
                >
                    <el-option
                    v-for="(item, index) in constants.creationNature"
                    :key="index"
                    :label="item.label"
                    :value="index"
                    >
                    </el-option>
                </el-select>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '1'">
                    <div slot="description">
                    作者未参考或使用已存在作品创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '2'">
                    <div slot="description">
                    作者参考已存在作品衍生创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '3'">
                    <div slot="description">
                    作者参考或使用已存在的外语作品衍生创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '4'">
                    <div slot="description">
                    作者参考或使用已存在作品衍生创作的系列。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '5'">
                    <div slot="description">
                    作者使用已存在作品衍生创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '6'">
                    <div slot="description">
                    作者参考或使用已存在作品衍生创作的合集。
                    </div>
                </a-alert>
                <!-- <a-alert class="tips" type="warning" v-if="form.creationNature == '7'">
                    <div slot="description">
                    请添加作品性质说明。
                    </div>
                </a-alert> -->
              </el-form-item>

            <el-form-item
              label="上传作品"
              ref="uploader"
              class="uploadWraper"
               prop="files"
            >
              <el-upload
                class="commonUpload"
                drag
                :action="uploadUrl"
                :headers="uploadHeaders"
                :on-success="uploadSuccess"
                :on-remove="removeFile"
                 :before-upload="uploadBefore"
                multiple
              >
                <div class="commonUploadInner">
                  <i class="iconfont iconUpload">&#xe606;</i>
                  <div class="el-upload__text">
                    <div>将文件拖到此处，或<em>点击上传</em></div>
                    <div> 支持扩展名：.rar .zip .doc .docx .pdf .jpg... </div>
                  </div>
                </div>

              </el-upload>
              <a-alert
                class="tips"
                type="warning"
              >
                <div slot="message"> 温馨提示：</div>
                <div slot="description">1、同一批的存证文件归属于同一标签，不支持重置；<br>
                  2、支持文件批量上传，一次不能超过10个文件；<br>
                  3、文件永久保全。<br>
                  4、如需做版权登记，请在此处将证书及作品属性相关文件一并上传。<br>
                  5、超过500M的文件，存证收费将会相应增加。 </div>
              </a-alert>
            </el-form-item>
             <el-form-item
              label="存储保障"
            >
              <a-alert
                class="tipsGray"
              >
                <div slot="description">
                  1、只需花费三分钟即可免费办理的区块链存证证书；<br>
                  2、能够快速有效地固定作品信息。作品信息将被上传到区块链，获得精准授时，信息永久不可篡改。<br>
                  3、版权所有者日后进行版权授权交易及维权时，区块链存证证书都将成为有效的证据。<br>
                  4、同步上传多个主流链。
                </div>
              </a-alert>
            </el-form-item>
             <!-- <el-form-item label="支付方式" prop="paymode" class="choosePayStyle">
                <el-radio-group v-model="form.paymode">
                <el-radio label="vip"> <span class="onlyText">中国知链会员权益支付（当前存证次数可用{{vipInfo?vipInfo.existCount:0}}次）</span> </el-radio>
                <el-radio label="alipay"> <i class="iconfont aliPayColor"> &#xe634; </i> <span>支付宝支付</span></el-radio>
                 <el-radio label="wechat">  <i class="iconfont weChatColor"> &#xe633; </i> <span>微信支付</span></el-radio>
              </el-radio-group>
              <router-link class="buyVip" :to="{path:'/Vip'}">购买会员</router-link>
             </el-form-item>
             <el-form-item label="支付金额" v-if="form.paymode!='vip'">
              <div>  <em class="bigPrice">{{form.orderAmount}} </em> 元  </div>
            </el-form-item> -->
            <el-form-item label="支付方式" prop="paymode" class="choosePayStyle">
              <el-radio-group v-model="form.paymode">
                <el-radio label="wechat">  <i class="iconfont weChatColor"> &#xe678; </i> <span>微信支付</span></el-radio>
                <el-radio label="alipay"> <i class="iconfont aliPayColor"> &#xe675; </i> <span>支付宝支付</span></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="支付金额" v-if="form.paymode != 'vip'" class="payMount">
              <div class="amount">
                <p>¥ {{ form.orderAmount }} </p>
              </div>
            </el-form-item>

            <el-form-item class="submitRow">
              <el-button
                type="primary"
                @click="onSubmit"
              >提交存证</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>

    </div>
    <result-view  v-if="tabIdx=='tab2'" :orderInfo="form" @set-tab-idx="setTabIdx">
    </result-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { queryObligeeList } from "@/api/ObligeeService";
import choosePersonDialog from "../../../components/form/choosePersonDialog";
import resultView from './components/resultView'
import { mixinsScrollErrorPosition } from '../../../mixins'
import multipleSelect from "../../../components/form/multipleSelect";
import constants from "@/utils/constants";
export default {
  name: 'ExistingEvidenceInputForm',
    mixins: [mixinsScrollErrorPosition],
  components: { resultView , choosePersonDialog , multipleSelect},
  data () {
    return {
      constants,
      workCategoryText:'',
      creationNatureText: '',
      form: {
        subject: '',
        paymode: 'wechat',
        files:[],
        orderAmount: '0.00',
        author: '',
        right: [],
        workCategory: "",
        creationNature: "",
      },
      obligees: [],
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,
      uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
      uploadHeaders:{ 
         'Authorization': this.$store.getters.token,
         'Product-Mode': "READ",
         'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
         'Terminal-Type': "WEB",
         'Merchant-Id': "2"
         },
      rules: {
        subject: [
          { required: true, message: '请输入作品名称', trigger: 'blur' },
          { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
        ],
        workCategory: [
          { required: true, message: "请选择作品创作类型", trigger: "blur" },
        ],
        creationNature:[
          { required: true, message: "请选择作品创作性质", trigger: "blur" },
        ],
        files:[
           { required: true, message: '请上传作品', trigger: 'change' },
        ],
        author: [
          { required: true, message: '请选择作者', trigger: 'change' }
        ],
        right: [
          { required: true, message: '请选择权利人', trigger: 'blur' }
        ]
      },
      tabIdx: 'tab1',
    }
  },
  computed: {
    ...mapGetters(['mid','userInfo','evidencePaymentPrice','vipInfo'])
  },
  created() {
    this.loadData();
  },
  methods: {
      loadData() {
        queryObligeeList(1, 100).then(({ data }) => {
          this.obligees = data.list;
        });
      },
      async onSubmit () {
      console.log("onSubmit..")
      try {
        const formBaseResult = await this.$refs['form'].validate()
          if(this.form.files.length>10){
           this.$message.error("不能超过10个文件")
          return
        }
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error)
        this.mixinsScrollView()
        return
      }
      this.setTabIdx('tab2')
     },
    setTabIdx(tabIdx){
        this.tabIdx=tabIdx
    },
    onSetAuthorDialogClose() {
        this.isAuthorDialogVisible = false;
      },
      onSetRightDialogClose() {
        this.isRightDialogVisible = false;
      },
      onChooseAuthor(users) {
        this.form.author = users;
      },
      onChooseRight(users) {
        this.form.right = users;
      },
    calAmount(){
       let amount=0;
       let existConfig=this.evidencePaymentPrice.exist;
       if(this.form.files.length > 0){
        for (let i = 0; i < this.form.files.length; i++) {
          const file = this.form.files[i];
          //
          amount+=existConfig.basePrice*1+(Math.ceil(file.used*1/(1024*1024))* existConfig.sizePrice*1);

        }
        }else{
          amount = '0.00'
        }

       this.form.orderAmount=(amount).toFixed(2);
    },
    uploadBefore(file) {
        const NoPassFileTypes=['.exe','.dmg','.js','.html','.json','.xml','.dtd','.jsp']
        const fileName=file.name
        const fileEnd=fileName.substring(fileName.indexOf("."))
				const isFileTypePass = !NoPassFileTypes.includes(fileEnd)
				const isLt500M = file.size / 1024 / 1024 < 500;

				if (!isFileTypePass) {
					this.$message.error('上传文件格式不能为'+fileEnd);
				}
				if (!isLt500M) {
					this.$message.error('上传头像图片大小不能超过 500MB!');
				}
				return isFileTypePass&&isLt500M;
			},
    uploadSuccess(response, file, fileList){
        console.log(arguments)
        this.$refs.uploader.clearValidate()
        this.form.files.push(response);
        this.calAmount();
    },
    removeFile(file,fileList){
      let that=this;
      let response=file.response;
      if(response){
        let index=that.form.files.findIndex(t=>{
          return t.sourceCode==response.sourceCode;
        });
        if(index>-1){
          that.form.files.splice(index,1);
        }

      }
      console.log(file,fileList);
      // 更新价格
      this.calAmount();
    },

    // 选择作品类型
    selectworkCategory(index){
      this.workCategoryText = this.constants.worksType[index].label;
      this.form.workCategory = this.constants.worksType[index].value;
      console.log(this.form.workCategory);
    },

    // 选择作品创作性质
    selectCreationNature(index){
      this.creationNatureText = this.constants.creationNature[index].label;
      this.form.creationNature = this.constants.creationNature[index].value;
    },

  },
  // beforeRouteLeave(to, from, next) {
  //   this.$confirm("系统可能不会保存您所做的更改。", "离开此页面？", {
  //     confirmButtonText: "离开",
  //     cancelButtonText: "取消",
  //     type: "warning"
  //   })
  //     .then(() => {
  //       // confirmButton回调
  //       next()
  //     })
  //     .catch(() => {
  //       // cancelButton回调，把当前页路由推回
  //       // 不能使用this.$router进行路由操作，会触发beforeRouteLeave导致死循环
  //       window.history.pushState("", "", this.currentUrl)
  //     })
  // },
}
</script>

<style lang='scss' scoped>
  @import "../../../assets/css/inputForm.scss";
.tips {
  // position: initial;
  position: absolute;
    width: 384px;
    right: -410px;
    top: 0;
}

.el-radio-group{display: flex;align-items: center;}
.el-radio {
    color: #606266;
    font-weight: 500;
    cursor: pointer;
    margin-right: 30px!important;
    margin-bottom: 0!important;
}
.choosePayStyle /deep/.el-radio__label{border: none!important;}
.amount p{
    color: #316EF4;
    font-size: 22px;
}

</style>
